<template>
	<div class="groupManage">
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus"
					@click.stop="dialogVisible = true, dialogType = 1, form = { type:'1', target: [] }">新增</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="创建时间" prop="created_at" align="center" width="160"></el-table-column>
				<el-table-column label="内容类型" align="center" width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">文本</span>
						<span v-if="scope.row.type == 2">图片</span>
						<span v-if="scope.row.type == 3">视频</span>
						<span v-if="scope.row.type == 4">音频</span>
					</template>
				</el-table-column>
				<el-table-column label="发送内容" prop="content" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.type == 1" v-html="scope.row.content"></div>
						<el-image style="width: 100px; height: 100px" :src="scope.row.content"
							:preview-src-list="[scope.row.content]" v-if="scope.row.type == 2">
						</el-image>
						<video style="width: 160px; height: 90px" controls :src="scope.row.content"
							v-if="scope.row.type == 3"></video>
						<audio style="width: 160px; height: 50px" controls :src="scope.row.content"
							v-if="scope.row.type == 4"></audio>
					</template>
				</el-table-column>
				<el-table-column label="执行频率" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.rate == 1">定时推送</span>
						<span v-if="scope.row.rate == 2">每日定时推送</span>
					</template>
				</el-table-column>
				<el-table-column label="执行时间" prop="exec_time" align="center"></el-table-column>
				<el-table-column label="群组/好友" prop="target_names" align="center"></el-table-column>
				<el-table-column label="状态" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 1" style="color:green">进行中</span>
						<span v-if="scope.row.status == 2">已结束</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="warning" size="mini"
							@click.stop="dialogVisible = true, dialogType = 2, openDialog(scope.row)">编辑</el-button>
						<el-button type="danger" size="mini" @click.stop="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="dialogType == 1 ? '新增' : '编辑'" :visible.sync="dialogVisible" :close-on-click-modal="false"
			v-el-drag-dialog width="30%">
			<el-form ref="form" :model="form" label-width="80px">

				<el-form-item label="内容类型" required>
					<el-radio-group v-model="form.type" @input="changeType">
						<el-radio :label="1">文字</el-radio>
						<el-radio :label="2">图片</el-radio>
						<el-radio :label="4">视频</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="发送文本" v-if="form.type == 1">
					<el-input type="textarea" v-model="form.content" placeholder="请输入发送内容" maxlength="200" rows="5"
						show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="发送图片" v-if="form.type == 2">
					<el-upload class="avatar-uploader" v-loading="uploading" :on-progress="handleAvatarProcess"
						:action="serverUrl" :headers="uploadHeaders" :data="uploadParam" :show-file-list="false"
						:on-success="handleAvatarSuccess">
						<img v-if="form.pic" :src="form.pic" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="发送视频" v-if="form.type == 4">
					<el-upload class="avatar-uploader" v-loading="uploading" :on-progress="handleAvatarProcess"
						:action="serverUrl" :headers="uploadHeaders" :data="uploadParam" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeUploadVideo">
						<video controls v-if="form.video" :src="form.video" class="avatar"></video>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>

				<el-form-item label="发送频率" required>
					<el-select v-model="form.rate" placeholder="请选择发送频率" style="width: 100%;">
						<el-option v-for="item in rateList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="发送时间" required>
					<el-date-picker v-model="form.exec_time" type="datetime" placeholder="选择发送时间"
						value-format="yyyy-MM-dd HH:mm:ss" style="width:100%">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="好友：" v-if="wxUserList.length > 0" required>
					<el-checkbox-group v-model="form.target">
						<el-checkbox v-for="item in wxUserList" :key="item.id" :label="item.id">{{ item.nickname
                        }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="群组：" v-if="wxGroupList.length > 0" required>
					<el-checkbox-group v-model="form.target">
						<el-checkbox v-for="item in wxGroupList" :key="item.id" :label="item.id">{{ item.nickname
                        }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import CryptoJS from 'crypto-js'
	var timestamp = new Date().getTime()
	export default {
		data() {
			return {
				searchForm: {},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				// 新增、编辑弹窗
				rateList: [{
						id: 1,
						name: '定时推送'
					},
					{
						id: 2,
						name: '每日定时推送'
					}
				],
				wxList: [],
				wxUserList: [],
				wxGroupList: [],
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				form: {
					type: "1",
					target: []
				},
				//上传相关
				uploadParam: {

				},
				uploadHeaders: {
					token: window.sessionStorage.getItem('token'),
					sign: CryptoJS.MD5(sessionStorage.getItem('token') + 'w2MDYDE8nx4CQdA8fn2Nmnexn4bSyBbh' + timestamp)
						.toString(),
					timestamp: timestamp
				},
				serverUrl: 'erp/v1/common/upload',
				uploading: false
			}
		},
		mounted() {
			this.getList()
			this.getWxAll()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/wx_task/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 编辑弹窗
			openDialog(item) {
				this.form = {
					...item
				}
				if (this.form.type == 2) {
					this.form.pic = this.form.content
				}
				if (this.form.type == 3) {
					this.form.type = 4
					this.form.video = this.form.content
				}
				this.form.target = this.form.target.split(",").map(Number)
			},
			// 确定
			confirmFn() {
				if (this.form.type == 2) {
					this.form.content = this.form.pic
				}
				if (this.form.type == 4) {
					this.form.content = this.form.video
					this.form.type = 3
				}
				if (!this.form.content) {
					this.$message.error('请输入发送内容');
					return
				}
				if (!this.form.rate) {
					this.$message.error('请选择发送频率');
					return
				}
				if (!this.form.exec_time) {
					this.$message.error('请选择发送时间');
					return
				}
				if (this.form.target.length <= 0) {
					this.$message.error('请选择群组/好友');
					return
				}
				this.form.target = this.form.target.toString()
				let url = this.dialogType == 1 ? '/erp/v1/wx_task/add' : '/erp/v1/wx_task/modify'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 删除
			removeFn(item) {
				this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/wx_task/delete`, {
						id: item.id
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 微信群组
			getWxAll() {
				this.wxUserList = []
				this.wxGroupList = []
				this.$http.post(`/erp/v1/wx_groups/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.wxList = data
						this.wxList.forEach(item => {
							if (item.type == 1) {
								this.wxUserList.push(item)
							} else {
								this.wxGroupList.push(item)
							}
						})
						console.log(this.wxUserList, 'this.wxUserList');
						console.log(this.wxGroupList, 'this.wxGroupList');
					} else {
						this.$message.error(msg);
					}
				});
			},
			changeType(val) {
				if (val < 4) {
					this.$set(this.uploadParam, "type", 1)
					this.uploadParam.type == "1"
				} else {
					this.$set(this.uploadParam, "type", 4)
					this.uploadParam.type == "4"
				}
			},
			handleAvatarSuccess(res, file) {
				this.uploading = false
				if (this.form.type == 2) {
					this.form.pic = res.data.path
				} else {
					this.form.video = res.data.path
				}

				this.$forceUpdate();
			},
			handleAvatarProcess(res, file) {
				this.uploading = true
			},
			beforeUploadVideo(file) {
				let isLt25M = file.size / 1024 / 1024 < 25; // 小于25MB
				if (!isLt25M) {
					this.$message.error('上传的文件大小不能超过 25MB!');
				}
				return isLt25M;
			}
		},
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #FF720E;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #FF720E;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 125PX;
		height: 125PX;
		line-height: 125PX;
		text-align: center;
	}

	.avatar {
		width: 125PX;
		height: 125PX;
		display: block;
	}
</style>